import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import HeroesLinksList from "@/game-deadlock/components/HeroesLinksList.jsx";
import HeroPageHeader from "@/game-deadlock/components/HeroPageHeader.jsx";
import { TableContainer } from "@/game-deadlock/HeroAbilities.style.jsx";
import { Content } from "@/game-deadlock/HeroOverview.style.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { getHeroIdFromName } from "@/game-deadlock/utils/getHeroIdFromName.mjs";
import { localeString } from "@/game-deadlock/utils/localestring.mjs";
import { useHeroStats } from "@/game-deadlock/utils/use-hero-stats.mjs";
import { useHeroesList } from "@/game-deadlock/utils/use-heroes-list.mjs";
import { useItemsAbilities } from "@/game-deadlock/utils/use-items-abilties.mjs";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

function HeroAbilities() {
  const { t } = useTranslation();
  const {
    parameters: [heroIdOrName],
  } = useRoute();

  const { loading: _heroesListLoading, list: _heroesList } = useHeroesList();
  const {
    loading: _itemsLoading,
    dict: abilitiesStaticData,
    names: abilitiesStaticNames,
  } = useItemsAbilities();
  const {
    loading: heroStatsLoading,
    stats: heroStats,
    heroId,
    heroName,
  } = useHeroStats(heroIdOrName);

  return (
    <PageContainer>
      <HeroPageHeader heroId={heroId} heroName={heroName} stats={heroStats} />
      <section className={Content()}>
        <div className="main-col">
          {heroStatsLoading ? (
            <DataTableLoading tallRows loadingRows={4} />
          ) : (
            <DataTable
              tallRows
              indexCol
              sortCol={1} // win rate
              className={TableContainer()}
              cols={[
                {
                  align: "left",
                  display: t(
                    "deadlock:stats.firstMaxxedAbility",
                    "First Maxed Ability",
                  ),
                  className: "ability-meta",
                },
                {
                  display: t("common:stats.winRate", "Win Rate"),
                  className: "winrate",
                  isStat: true,
                },
                {
                  display: t("common:stats.pickRate", "Pick Rate"),
                  className: "pickrate",
                  isStat: true,
                },
                {
                  display: t("common:stats.matches", "Matches"),
                  className: "matches",
                  isStat: true,
                },
              ]}
              rows={(heroStats.max_ability_seq_first_1_stats || []).map(
                (ability) => {
                  const abilityID = ability.ability_id_seq[0];
                  const abilityStaticData = abilitiesStaticData[abilityID];

                  return [
                    {
                      display: (
                        <div className="ability-meta">
                          <img
                            src={Assets.getItemAbilityImage(abilityStaticData)}
                            width="40"
                            height="40"
                            data-id={abilityID}
                          />
                          <span className="ability-name">
                            {abilitiesStaticNames[abilityID] || ""}
                          </span>
                        </div>
                      ),
                      value: abilityID,
                    },
                    {
                      display: localeString(ability.win_rate, true, 1),
                      value: ability.win_rate,
                    },
                    {
                      display: localeString(ability.pick_rate, true, 1),
                      value: ability.pick_rate,
                    },
                    {
                      display: localeString(ability.num_matches, false, 0),
                      value: ability.num_matches,
                    },
                  ];
                },
              )}
            />
          )}
        </div>
        <aside className="side-col">
          <HeroesLinksList heroIdOrName={heroIdOrName} tab="abilities" />
        </aside>
      </section>
    </PageContainer>
  );
}

export default HeroAbilities;

export function meta([heroIdOrName]: [string]) {
  const heroId = getHeroIdFromName(heroIdOrName, readState.deadlock.heroes);
  const hero = readState.deadlock.heroes[heroId];
  const name = hero?.heroName;
  return {
    title: name
      ? [
          "deadlock:meta.heroPage.title",
          "{{name}}'s Deadlock Win Rate, Pick Rate, and tier list – Blitz Deadlock",
          { name },
        ]
      : [
          "deadlock:meta.tierlist.title",
          "Deadlock Tier list, Win Rates, Pick Rates, and more – Blitz Deadlock",
        ],
    description: [],
    subtitle: name
      ? [
          "deadlock:meta.heroNameSubtitle",
          "Deadlock {{name}} Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
          { name },
        ]
      : [
          "deadlock:meta.heroSubtitle",
          "Deadlock Hero Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
        ],
  };
}
